// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Switch_switch__7EnGG {
  display: flex;
  align-items: center;
}

.Switch_input__LzKvk {
  display: none;
}

.Switch_label__kXa-d {
  padding-left: 49px;
  position: relative;
  cursor: pointer;
  font-weight: 700;
}
.Switch_label__kXa-d::before, .Switch_label__kXa-d::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.Switch_label__kXa-d::after {
  left: 3px;
  background: var(--white-color);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  transition: transform 0.2s ease;
}
.Switch_label__kXa-d::before {
  width: 33px;
  height: 18.5px;
  left: 0;
  border-radius: 23px;
  background: var(--dark-gray-color);
}

.Switch_input__LzKvk:checked + .Switch_label__kXa-d::before {
  background: var(--primary-color);
}
.Switch_input__LzKvk:checked + .Switch_label__kXa-d::after {
  transform: translate(90%, -50%);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Switch/Switch.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAGA;EACE,aAAA;AAAF;;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAAF;AAEE;EAEE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,QAAA;EACA,2BAAA;AADJ;AAIE;EACE,SAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,+BAAA;AAFJ;AAKE;EACE,WAAA;EACA,cAAA;EACA,OAAA;EACA,mBAAA;EACA,kCAAA;AAHJ;;AAUI;EACE,gCAAA;AAPN;AAUI;EACE,+BAAA;AARN","sourcesContent":[".switch {\n  display: flex;\n  align-items: center;\n}\n\n\n.input {\n  display: none;\n}\n\n.label {\n  padding-left: 16px + 33px;\n  position: relative;\n  cursor: pointer;\n  font-weight: 700;\n\n  &::before,\n  &::after {\n    content: '';\n    display: block;\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  &::after {\n    left: 3px;\n    background: var(--white-color);\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    transition: transform 0.2s ease;\n  }\n\n  &::before {\n    width: 33px;\n    height: 18.5px;\n    left: 0;\n    border-radius: 23px;\n    background: var(--dark-gray-color);\n  }\n}\n\n\n.input:checked {\n  &+.label {\n    &::before {\n      background: var(--primary-color);\n    }\n\n    &::after {\n      transform: translate(90%, -50%);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switch": `Switch_switch__7EnGG`,
	"input": `Switch_input__LzKvk`,
	"label": `Switch_label__kXa-d`
};
export default ___CSS_LOADER_EXPORT___;
