// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea_textarea__GIZvb {
  background: var(--gray-bg-color);
  padding: 12px 20px;
  border-radius: 9px;
  resize: none;
  border: 1px solid transparent;
  transition: border 0.2s ease-in-out;
}

.Textarea_error__sQxXg {
  border: 1px solid var(--red-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Textarea/Textarea.module.scss"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,6BAAA;EACA,mCAAA;AACF;;AAEA;EACE,kCAAA;AACF","sourcesContent":[".textarea {\n  background: var(--gray-bg-color);\n  padding: 12px 20px;\n  border-radius: 9px;\n  resize: none;\n  border: 1px solid transparent;\n  transition: border .2s ease-in-out;\n}\n\n.error {\n  border: 1px solid var(--red-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `Textarea_textarea__GIZvb`,
	"error": `Textarea_error__sQxXg`
};
export default ___CSS_LOADER_EXPORT___;
