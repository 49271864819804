// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Avatar_icon__RPctV {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Avatar/Avatar.module.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAAF","sourcesContent":["\n.icon {\n  object-fit: cover;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Avatar_icon__RPctV`
};
export default ___CSS_LOADER_EXPORT___;
