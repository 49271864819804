// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangePasswordForm_changePasswordForm__yO6ZW {
  display: flex;
  flex-direction: column;
}

.ChangePasswordForm_title__tYuX7 {
  margin-bottom: 40px;
}

.ChangePasswordForm_input__BUYcN:not(:last-child) {
  margin-bottom: 16px;
}

.ChangePasswordForm_buttons__tnVQk {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: space-between;
}

.ChangePasswordForm_btn__7qtio {
  padding: 9px;
  width: 190px;
}`, "",{"version":3,"sources":["webpack://./src/features/ChangePassword/ui/ChangePasswordForm/ChangePasswordForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAGE;EACE,mBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;AADF;;AAIA;EACE,YAAA;EACA,YAAA;AADF","sourcesContent":[".changePasswordForm {\n  display: flex;\n  flex-direction: column;\n}\n\n.title {\n  margin-bottom: 40px;\n}\n\n.input {\n  &:not(:last-child) {\n    margin-bottom: 16px;\n  }\n}\n\n.buttons {\n  margin-top: 30px;\n  display: flex;\n  flex-direction: row;\n  gap: 30px;\n  justify-content: space-between;\n}\n\n.btn {\n  padding: 9px;\n  width: 190px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changePasswordForm": `ChangePasswordForm_changePasswordForm__yO6ZW`,
	"title": `ChangePasswordForm_title__tYuX7`,
	"input": `ChangePasswordForm_input__BUYcN`,
	"buttons": `ChangePasswordForm_buttons__tnVQk`,
	"btn": `ChangePasswordForm_btn__7qtio`
};
export default ___CSS_LOADER_EXPORT___;
