// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DateInput_dateInput__iZpLQ {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.DateInput_calendarForm__Aj26h {
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 50px;
  background: var(--gray-bg-color);
  border-radius: 10px;
  pointer-events: none;
  z-index: -1;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
  transition: opacity 0.2s ease-in-out;
}

.DateInput_center__RfcNQ {
  top: -100px;
}

.DateInput_down__3a3bV {
  top: 270px;
}

.DateInput_isFocused__RkI5Y {
  opacity: 1;
  z-index: 100;
  pointer-events: all;
}

.DateInput_buttons__zgh2y {
  display: flex;
  margin: 0 -30px;
  margin-top: 30px;
}

.DateInput_button__IyI7x {
  margin: 0 30px;
  flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./src/widgets/DateInput/ui/DateInput.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,kBAAA;EACA,SAAA;EACA,gCAAA;EACA,kBAAA;EACA,gCAAA;EACA,mBAAA;EACA,oBAAA;EACA,WAAA;EACA,gDAAA;EACA,oCAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,cAAA;EACA,cAAA;AACF","sourcesContent":[".dateInput {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n}\n\n.calendarForm {\n  opacity: 0;\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 40px 50px;\n  background: var(--gray-bg-color);\n  border-radius: 10px;\n  pointer-events: none;\n  z-index: -1;\n  box-shadow: 0px 4px 10px 0px var(--shadow-color);\n  transition: opacity .2s ease-in-out;\n}\n\n.center {\n  top: -100px;\n}\n\n.down {\n  top: 270px;\n}\n\n.isFocused {\n  opacity: 1;\n  z-index: 100;\n  pointer-events: all;\n}\n\n.buttons {\n  display: flex;\n  margin: 0 -30px;\n  margin-top: 30px;\n}\n\n.button {\n  margin: 0 30px;\n  flex: 1 1 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateInput": `DateInput_dateInput__iZpLQ`,
	"calendarForm": `DateInput_calendarForm__Aj26h`,
	"center": `DateInput_center__RfcNQ`,
	"down": `DateInput_down__3a3bV`,
	"isFocused": `DateInput_isFocused__RkI5Y`,
	"buttons": `DateInput_buttons__zgh2y`,
	"button": `DateInput_button__IyI7x`
};
export default ___CSS_LOADER_EXPORT___;
