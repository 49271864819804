// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormInput_errors__JIJ49 {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.FormInput_error__osUk9 {
  font: var(--font-s2);
  color: var(--red-color);
  font-weight: bold;
  padding: 0 17px;
}

.FormInput_inputRow__1tFE0 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}`, "",{"version":3,"sources":["webpack://./src/shared/lib/hooks/useForm/ui/FormInput.module.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAAJ;;AAGA;EACI,oBAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;AAAJ;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,oBAAA;AAAJ","sourcesContent":["\n.errors {\n    display: flex;\n    flex-direction: column;\n    gap: 1px;\n}\n\n.error {\n    font: var(--font-s2);\n    color: var(--red-color);\n    font-weight: bold;\n    padding: 0 17px;\n}\n\n.inputRow {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errors": `FormInput_errors__JIJ49`,
	"error": `FormInput_error__osUk9`,
	"inputRow": `FormInput_inputRow__1tFE0`
};
export default ___CSS_LOADER_EXPORT___;
