// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tag_tag__u2t9s {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  font: var(--font-s2);
  font-weight: 700;
  color: var(--white-color);
  padding: 6px 8px;
}

.Tag_new__E\\+VRG {
  background: var(--dark-gray-color);
}

.Tag_coordination__Cj-LF {
  background: var(--yellow-color);
}

.Tag_paymentCoordination__\\+KIFl {
  background: var(--yellow-color);
}

.Tag_inWork__ad-gm {
  background: var(--green-color);
}

.Tag_processed__SPUm3 {
  background: var(--green-color);
}

.Tag_finished__nbwEp {
  background: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Tag/Tag.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,yBAAA;EACA,gBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,gCAAA;AACF","sourcesContent":[".tag {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 23px;\n  font: var(--font-s2);\n  font-weight: 700;\n  color: var(--white-color);\n  padding: 6px 8px;\n}\n\n.new {\n  background: var(--dark-gray-color);\n}\n\n.coordination {\n  background: var(--yellow-color);\n}\n\n.paymentCoordination {\n  background: var(--yellow-color);\n}\n\n.inWork {\n  background: var(--green-color);\n}\n\n.processed {\n  background: var(--green-color);\n}\n\n.finished {\n  background: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `Tag_tag__u2t9s`,
	"new": `Tag_new__E+VRG`,
	"coordination": `Tag_coordination__Cj-LF`,
	"paymentCoordination": `Tag_paymentCoordination__+KIFl`,
	"inWork": `Tag_inWork__ad-gm`,
	"processed": `Tag_processed__SPUm3`,
	"finished": `Tag_finished__nbwEp`
};
export default ___CSS_LOADER_EXPORT___;
