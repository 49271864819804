// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableHeader_collapsableTableHeader__nHgz- {
  background: #ffffff;
  margin-top: -2px;
  margin-bottom: -1px;
}
.TableHeader_collapsableTableHeader__nHgz- > div {
  background: unset;
  margin-left: -1px;
  margin-right: -1px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Table/ui/TableHeader/TableHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAAI;EACA,iBAAA;EACI,iBAAA;EACA,kBAAA;AAER","sourcesContent":[".collapsableTableHeader {\n    background: #ffffff;\n    margin-top: -2px;\n    margin-bottom: -1px;\n    > div {\n    background: unset;\n        margin-left: -1px;\n        margin-right: -1px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsableTableHeader": `TableHeader_collapsableTableHeader__nHgz-`
};
export default ___CSS_LOADER_EXPORT___;
