// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationIcon_notificationIcon__ZgPHx {
  position: absolute;
  top: 16px;
  left: 12px;
  transition: all 0.3s ease-in-out;
}
.NotificationIcon_notificationIcon__ZgPHx.NotificationIcon_active__8T1Bq {
  top: 26px;
  left: 20px;
}

.NotificationIcon_onAnimate__YhKJ1 {
  transform-origin: 0 50%;
  animation-name: NotificationIcon_blueIcon__81xME;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes NotificationIcon_blueIcon__81xME {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(55deg);
  }
  10% {
    transform: rotate(0);
  }
  15% {
    transform: rotate(55deg);
  }
  20% {
    transform: rotate(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/features/Notifications/ui/NotificationIcon/NotificationIcon.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,gCAAA;AACF;AACE;EACE,SAAA;EACA,UAAA;AACJ;;AAGA;EACE,uBAAA;EACA,gDAAA;EACA,+BAAA;EACA,sBAAA;EACA,mCAAA;AAAF;;AAGA;EACE;IACE,oBAAA;EAAF;EAEA;IACE,wBAAA;EAAF;EAEA;IACE,oBAAA;EAAF;EAEA;IACE,wBAAA;EAAF;EAEA;IACE,oBAAA;EAAF;AACF","sourcesContent":[".notificationIcon {\n  position: absolute;\n  top: 16px;\n  left: 12px;\n  transition: all 0.3s ease-in-out;\n\n  &.active {\n    top: 26px;\n    left: 20px;\n  }\n}\n\n.onAnimate {\n  transform-origin: 0 50%;\n  animation-name: blueIcon ;\n  animation-timing-function: ease;\n  animation-duration: 2s;\n  animation-iteration-count: infinite;\n}\n\n@keyframes blueIcon {\n  0% { \n    transform: rotate(0);\n  }\n  5% {\n    transform: rotate(55deg);\n  }\n  10% {\n    transform: rotate(0);\n  }\n  15% {\n    transform: rotate(55deg);\n  }\n  20% {\n    transform: rotate(0);\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationIcon": `NotificationIcon_notificationIcon__ZgPHx`,
	"active": `NotificationIcon_active__8T1Bq`,
	"onAnimate": `NotificationIcon_onAnimate__YhKJ1`,
	"blueIcon": `NotificationIcon_blueIcon__81xME`
};
export default ___CSS_LOADER_EXPORT___;
