import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChangePasswordSchema, ChangePasswordFormData } from '../type/changePassword';
import { changePasswordService } from '../services/changePasswordService';

const initialState: ChangePasswordSchema = {
  isLoading: false,
  formData: {
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
  },
  error: undefined,
  isOpen: false,
};

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    setOldPassword: (state, action: PayloadAction<string>) => {
      state.formData.oldPassword = action.payload;
    },
    setNewPassword: (state, action: PayloadAction<string>) => {
      state.formData.newPassword = action.payload;
    },
    setRepeatPassword: (state, action: PayloadAction<string>) => {
      state.formData.repeatPassword = action.payload;
    },
    clearForm: (state) => {
      state.formData = {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      };
    },
    openForm: (state) => {
      state.isOpen = true;
    },
    closeForm: (state) => {
      state.isOpen = false;
      state.formData = {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      };
    },
  },
  extraReducers: (builder) => builder
    .addCase(changePasswordService.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    })
    .addCase(changePasswordService.fulfilled, (state) => {
      state.isLoading = false;
      state.formData = {
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
      };
    })
    .addCase(changePasswordService.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }),
});

export const { actions: changePasswordActions } = changePasswordSlice;
export const { reducer: changePasswordReducer } = changePasswordSlice;
