// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SidebarItem_icon__LqLoR {
  fill: var(--black-color);
  stroke: var(--black-color);
  height: 100%;
}

.SidebarItem_iconContainer__bEt8B {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

.SidebarItem_link__s9b0d {
  display: flex;
  align-items: center;
  padding: 13px 0 13px 10px;
}
.SidebarItem_link__s9b0d.SidebarItem_active__S1Ns1, .SidebarItem_link__s9b0d:hover {
  color: var(--white-color);
  background: var(--primary-color);
  font-weight: 700;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
}
@media (max-width: 760px) {
  .SidebarItem_link__s9b0d {
    justify-content: center;
  }
}

.SidebarItem_link__s9b0d.SidebarItem_active__S1Ns1 > .SidebarItem_iconContainer__bEt8B > .SidebarItem_icon__LqLoR,
.SidebarItem_link__s9b0d:hover > .SidebarItem_iconContainer__bEt8B > .SidebarItem_icon__LqLoR {
  fill: var(--white-color);
  stroke: var(--white-color);
}

.SidebarItem_text__T0GmA {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.SidebarItem_collapsed__J0q5m .SidebarItem_link__s9b0d {
  justify-content: center;
}
.SidebarItem_collapsed__J0q5m .SidebarItem_text__T0GmA {
  display: block;
  width: 0;
  overflow: hidden;
}
@media (max-width: 760px) {
  .SidebarItem_collapsed__J0q5m .SidebarItem_text__T0GmA {
    overflow: auto;
    width: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/Sidebar/SidebarItem/SidebarItem.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,0BAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;AACF;AACE;EAEE,yBAAA;EACA,gCAAA;EACA,gBAAA;EACA,gDAAA;AAAJ;AAGE;EAbF;IAcI,uBAAA;EAAF;AACF;;AAGA;;EAEE,wBAAA;EACA,0BAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAAF;;AAIE;EACE,uBAAA;AADJ;AAIE;EACE,cAAA;EACA,QAAA;EACA,gBAAA;AAFJ;AAGI;EAJF;IAKI,cAAA;IACA,WAAA;EAAJ;AACF","sourcesContent":[".icon {\n  fill: var(--black-color);\n  stroke: var(--black-color);\n  height: 100%;\n}\n\n.iconContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 15px;\n  width: 20px;\n  height: 20px;\n}\n\n.link {\n  display: flex;\n  align-items: center;\n  padding: 13px 0 13px 10px;\n\n  &.active,\n  &:hover {\n    color: var(--white-color);\n    background: var(--primary-color);\n    font-weight: 700;\n    box-shadow: 0px 4px 10px 0px var(--shadow-color);\n  }\n\n  @media (max-width: 760px) {\n    justify-content: center;\n  }\n}\n\n.link.active>.iconContainer>.icon,\n.link:hover>.iconContainer>.icon {\n  fill: var(--white-color);\n  stroke: var(--white-color);\n}\n\n.text {\n  font-weight: 700;\n  font-size: 14px;\n  line-height: 17px;\n}\n.collapsed {\n\n  .link {\n    justify-content: center;\n  }\n\n  .text {\n    display: block;\n    width: 0;\n    overflow: hidden;\n    @media (max-width: 760px) {\n      overflow: auto;\n      width: auto;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `SidebarItem_icon__LqLoR`,
	"iconContainer": `SidebarItem_iconContainer__bEt8B`,
	"link": `SidebarItem_link__s9b0d`,
	"active": `SidebarItem_active__S1Ns1`,
	"text": `SidebarItem_text__T0GmA`,
	"collapsed": `SidebarItem_collapsed__J0q5m`
};
export default ___CSS_LOADER_EXPORT___;
