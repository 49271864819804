// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/check-blue-icon.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/icons/check-icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Checkbox_checkbox__QyvmJ {
  display: none;
}
.Checkbox_checkbox__QyvmJ:checked + .Checkbox_blue__G-ruj::before {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-bg-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
}
.Checkbox_checkbox__QyvmJ:checked + .Checkbox_label__MtOcg::before {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-position: center;
  background-repeat: no-repeat;
}

.Checkbox_checkboxContainer__VCvYi {
  display: flex;
  align-items: center;
}

.Checkbox_label__MtOcg {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  height: 16px;
  cursor: pointer;
}
.Checkbox_label__MtOcg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #848484;
  transition: border 0.2s ease-in-out;
}

.Checkbox_error__HscrX::before {
  border: 1px solid var(--red-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACI;EACE,sCAAA;EACA,yCAAA;EACA,yDAAA;EACA,wBAAA;AACN;AAEI;EACE,yDAAA;EACA,2BAAA;EACA,4BAAA;AAAN;;AAMA;EACE,aAAA;EACA,mBAAA;AAHF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAHF;AAKE;EACE,WAAA;EACA,kBAAA;EACA,OAAA;EACA,QAAA;EACA,2BAAA;EACA,eAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,mCAAA;AAHJ;;AAQE;EACE,kCAAA;AALJ","sourcesContent":[".checkbox {\n  display: none;\n  &:checked {\n    &+.blue::before {\n      border: 1px solid var(--primary-color);\n      background-color: var(--primary-bg-color);\n      background-image: url('../../assets/icons/check-blue-icon.svg');\n      background-size: contain;\n    }\n\n    &+.label::before {\n      background-image: url('../../assets/icons/check-icon.svg');\n      background-position: center;\n      background-repeat: no-repeat;\n    }\n\n  }   \n}\n\n.checkboxContainer {\n  display: flex;\n  align-items: center;\n}\n\n.label {\n  display: flex;\n  align-items: center;\n  position: relative;\n  padding-left: 24px;\n  height: 16px;\n  cursor: pointer;\n\n  &::before {\n    content: '';\n    position: absolute;\n    left: 0;\n    top: 50%;\n    transform: translateY(-50%);\n    cursor: pointer;\n    display: block;\n    width: 16px;\n    height: 16px;\n    border-radius: 3px;\n    border: 1px solid #848484;\n    transition: border .2s ease-in-out;\n  }\n}\n\n.error {\n  &::before {\n    border: 1px solid var(--red-color);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `Checkbox_checkbox__QyvmJ`,
	"blue": `Checkbox_blue__G-ruj`,
	"label": `Checkbox_label__MtOcg`,
	"checkboxContainer": `Checkbox_checkboxContainer__VCvYi`,
	"error": `Checkbox_error__HscrX`
};
export default ___CSS_LOADER_EXPORT___;
