// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationItem_notificationItem__able9 {
  padding: 25px 32px 25px 22px;
  display: flex;
  flex-direction: column;
}

.NotificationItem_collapsed__Jzxoy {
  display: none;
}

.NotificationItem_count__2fIvy {
  font-size: var(--font-size-s2);
  font-weight: var(--font-weight-xl);
  align-self: flex-end;
  margin-bottom: 10px;
}

.NotificationItem_text__JZVty {
  font-weight: var(--font-weight-s2);
}

.NotificationItem_body__6ttn4 {
  font-size: var(--font-size-s3);
  line-height: normal;
  margin-bottom: 8px;
}

.NotificationItem_moreBtn__aq2po {
  padding: 7px;
  font-size: var(--font-size-s2);
  align-self: flex-start;
  margin-top: 24px;
}

.NotificationItem_btns__kS-Oi {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.NotificationItem_btn__nD33A {
  padding: 7px;
}`, "",{"version":3,"sources":["webpack://./src/features/Notifications/ui/NotificationItem/NotificationItem.module.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,8BAAA;EACA,kCAAA;EACA,oBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kCAAA;AACJ;;AAEA;EACI,8BAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,8BAAA;EACA,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".notificationItem {\n    padding: 25px 32px 25px 22px;\n    display: flex;\n    flex-direction: column;\n}\n\n.collapsed {\n    display: none;\n}\n\n.count {\n    font-size: var(--font-size-s2);\n    font-weight: var(--font-weight-xl);\n    align-self: flex-end;\n    margin-bottom: 10px;\n}\n\n.text {\n    font-weight: var(--font-weight-s2);\n}\n\n.body {\n    font-size: var(--font-size-s3);\n    line-height: normal;\n    margin-bottom: 8px;\n}\n\n.moreBtn {\n    padding: 7px;\n    font-size: var(--font-size-s2);\n    align-self: flex-start;\n    margin-top: 24px;\n}\n\n.btns {\n    display: flex;\n    flex-direction: column;\n    gap: 11px;\n}\n\n.btn {\n    padding: 7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationItem": `NotificationItem_notificationItem__able9`,
	"collapsed": `NotificationItem_collapsed__Jzxoy`,
	"count": `NotificationItem_count__2fIvy`,
	"text": `NotificationItem_text__JZVty`,
	"body": `NotificationItem_body__6ttn4`,
	"moreBtn": `NotificationItem_moreBtn__aq2po`,
	"btns": `NotificationItem_btns__kS-Oi`,
	"btn": `NotificationItem_btn__nD33A`
};
export default ___CSS_LOADER_EXPORT___;
