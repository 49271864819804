// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportDetailModal_reportDetailModal__woKHh {
  max-width: 95%;
  width: 100%;
}

.ReportDetailModal_table__GNthj {
  padding-top: 50px;
  margin-bottom: 20px;
}

.ReportDetailModal_btns__fUneF {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/features/ReportDetail/ui/ReportDetailModal/ReportDetailModal.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;AACF","sourcesContent":[".reportDetailModal {\n  max-width: 95%;\n  width: 100%;\n}\n\n.table {\n  padding-top: 50px;\n  margin-bottom: 20px;\n}\n\n.btns {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportDetailModal": `ReportDetailModal_reportDetailModal__woKHh`,
	"table": `ReportDetailModal_table__GNthj`,
	"btns": `ReportDetailModal_btns__fUneF`
};
export default ___CSS_LOADER_EXPORT___;
