// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppLink_appLink__ikVax {
  text-decoration: none;
  font: var(--font-s);
}
@media (max-width: 760px) {
  .AppLink_appLink__ikVax {
    border-radius: 15px;
    box-shadow: 0px 4px 10px 0px var(--shadow-color);
    display: flex;
    align-items: center;
  }
}

.AppLink_blue__92nf9 {
  color: var(--primary-color);
  font-weight: 700;
}
.AppLink_blue__92nf9:hover {
  text-decoration: underline;
}

.AppLink_button__87zPd {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--dark-gray-color);
  color: var(--dark-gray-color);
  background: var(--white-color);
}
.AppLink_button__87zPd.AppLink_active__FuSj9, .AppLink_button__87zPd:hover {
  color: var(--primary-color);
  font-weight: 700;
  background: var(--primary-bg-color);
  border: none;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/AppLink/AppLink.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,mBAAA;AACF;AACE;EAJF;IAKI,mBAAA;IACA,gDAAA;IACA,aAAA;IACA,mBAAA;EAEF;AACF;;AACA;EACE,2BAAA;EACA,gBAAA;AAEF;AAAE;EACE,0BAAA;AAEJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,6BAAA;EACA,8BAAA;AAAF;AAEE;EAEE,2BAAA;EACA,gBAAA;EACA,mCAAA;EACA,YAAA;EACA,gDAAA;AADJ","sourcesContent":[".appLink {\n  text-decoration: none;\n  font: var(--font-s);\n\n  @media (max-width: 760px) {\n    border-radius: 15px;\n    box-shadow: 0px 4px 10px 0px var(--shadow-color);\n    display: flex;\n    align-items: center;\n  }\n}\n\n.blue {\n  color: var(--primary-color);\n  font-weight: 700;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n\n.button {\n  padding: 10px;\n  border-radius: 10px;\n  border: 1px solid var(--dark-gray-color);\n  color: var(--dark-gray-color);\n  background: var(--white-color);\n\n  &.active,\n  &:hover {\n    color: var(--primary-color);\n    font-weight: 700;\n    background: var(--primary-bg-color);\n    border: none;\n    box-shadow: 0px 4px 10px 0px var(--shadow-color);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLink": `AppLink_appLink__ikVax`,
	"blue": `AppLink_blue__92nf9`,
	"button": `AppLink_button__87zPd`,
	"active": `AppLink_active__FuSj9`
};
export default ___CSS_LOADER_EXPORT___;
