// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__TlG7I {
  padding: 24px 32px 11px 16px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 760px) {
  .Header_header__TlG7I {
    padding: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,aAAA;EACA,8BAAA;AACF;AACE;EALF;IAMI,aAAA;EAEF;AACF","sourcesContent":[".header {\n  padding: 24px 32px 11px 16px;\n  display: flex;\n  justify-content: space-between;\n\n  @media (max-width: 760px) {\n    padding: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__TlG7I`
};
export default ___CSS_LOADER_EXPORT___;
