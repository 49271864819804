// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_notifications__pqtIQ {
  position: relative;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  border-radius: 0 15px 15px 0;
  background-color: var(--gray-bg-color);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  min-height: 47px;
  max-width: var(--sidebar-width);
  transition: max-width 0.3s ease-in-out;
}

.Notifications_collapsed__5fDoG {
  max-width: var(--sidebar-collapsed-width);
}

.Notifications_alertBtn__W86tM {
  position: absolute;
  top: 11px;
  right: -10px;
  transition: top 0.3s ease-in-out;
}
.Notifications_alertBtn__W86tM.Notifications_active__LjfdD {
  top: 24px;
}

.Notifications_arrow__ocuf3 {
  width: 5px;
  height: 9;
}
.Notifications_arrow__ocuf3.Notifications_active__LjfdD {
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/features/Notifications/ui/Notifications/Notifications.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,sCAAA;EACA,gDAAA;EACA,gBAAA;EACA,+BAAA;EACA,sCAAA;AACJ;;AAEA;EACI,yCAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,YAAA;EACA,gCAAA;AACJ;AACI;EACI,SAAA;AACR;;AAGA;EACI,UAAA;EACA,SAAA;AAAJ;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".notifications {\n    position: relative;\n    margin-top: 64px;\n    display: flex;\n    flex-direction: column;\n    border-radius: 0 15px 15px 0;\n    background-color: var(--gray-bg-color);\n    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);\n    min-height: 47px;\n    max-width: var(--sidebar-width);\n    transition: max-width 0.3s ease-in-out;\n}\n\n.collapsed {\n    max-width: var(--sidebar-collapsed-width);\n}\n\n.alertBtn {\n    position: absolute;\n    top: 11px;\n    right: -10px;\n    transition: top 0.3s ease-in-out;\n\n    &.active {\n        top: 24px;\n    }\n}\n\n.arrow {\n    width: 5px;\n    height: 9;\n\n    &.active {\n        transform: rotate(180deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notifications": `Notifications_notifications__pqtIQ`,
	"collapsed": `Notifications_collapsed__5fDoG`,
	"alertBtn": `Notifications_alertBtn__W86tM`,
	"active": `Notifications_active__LjfdD`,
	"arrow": `Notifications_arrow__ocuf3`
};
export default ___CSS_LOADER_EXPORT___;
