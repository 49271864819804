import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers';
import { Application, ApplicationStatus } from 'entities/Application';
import { ApplicationsPageSchema } from '../type/applicationsPage';
import { fetchApplicationsList } from '../services/fetchApplicationsList/fetchApplicationsList';
import { deleteCheckedItems } from '../services/deleteCheckedItems/deleteCheckedItems';

export const applicationsPageAdapter = createEntityAdapter<Application>({
  selectId: (application) => application.id,
});

export const getApplicationsPage = applicationsPageAdapter.getSelectors<StateSchema>(
  (state) => state.applicationsPage || applicationsPageAdapter.getInitialState(),
);

const extractPageNumber = (url: string | null): number | null => {
  if (!url) return null;
  const match = url.match(/[?&]page=(\d+)/);
  return match ? Number(match[1]) : null;
};

export const applicationsPageSlice = createSlice({
  name: 'applicationsPage',
  initialState: applicationsPageAdapter.getInitialState<ApplicationsPageSchema>({
    ids: [],
    entities: {},
    isLoading: false,
    error: undefined,
    allIsChecked: false,
    checkedItems: [],
    modalIsOpen: false,
    _init: false,
    workObject: undefined,
    status: undefined,
    isCalendarOpen: false,
    creator: undefined,
    sort: undefined,
    startWorkDate: undefined,
    endWorkDate: undefined,
    count: 0,
    next: null,
    previous: null,
    currentPage: 1,
    totalPages: 0,
  }),
  reducers: {
    toggleCheckbox: (state, action: PayloadAction<string>) => {
      if (state.checkedItems?.includes(action.payload)) {
        state.checkedItems = state.checkedItems.filter((id) => id !== action.payload);
      } else {
        state.checkedItems?.push(action.payload);
      }
    },
    toggleAllCheckboxes: (state) => {
      const toggledItem = !state.allIsChecked;
      state.allIsChecked = toggledItem;
      if (toggledItem) {
        const itemIds = Object.values(state.entities).map((entity) => entity?.id) as string[];
        state.checkedItems = itemIds;
      } else {
        state.checkedItems = [];
      }
    },
    openModal: (state) => {
      state.modalIsOpen = true;
    },
    closeModal: (state) => {
      state.modalIsOpen = false;
    },
    initPage: (state) => {
      state._init = true;
    },
    setWorkObject: (state, action: PayloadAction<number | undefined>) => {
      state.workObject = action.payload;
    },
    toggleFinishedApplications: (state) => {
      state.showFinishedApplicaitons = !state.showFinishedApplicaitons;
    },
    setStatus: (state, action: PayloadAction<ApplicationStatus | undefined>) => {
      state.status = action.payload;
      applicationsPageAdapter.removeAll(state);
    },
    setCreator: (state, action: PayloadAction<string | undefined>) => {
      state.creator = action.payload;
      applicationsPageAdapter.removeAll(state);
    },
    setStartWorkDate: (state, action: PayloadAction<string>) => {
      state.startWorkDate = action.payload;
    },
    setEndWorkDate: (state, action: PayloadAction<string>) => {
      state.endWorkDate = action.payload;
    },
    clearWorkDates: (state) => {
      state.startWorkDate = '';
      state.endWorkDate = '';
    },
    openCalendar: (state) => {
      state.isCalendarOpen = true;
    },
    closeCalendar: (state) => {
      state.isCalendarOpen = false;
    },
    setSort: (state, action: PayloadAction<undefined | 'createdAt' | '-createdAt'>) => {
      state.sort = action.payload;
      applicationsPageAdapter.removeAll(state);
    },
    resetFilters: (state) => {
      state.workObject = undefined;
      state.status = undefined;
      state.creator = undefined;
      state.startWorkDate = '';
      state.endWorkDate = '';
      state.sort = undefined;
    },
    nextPage: (state, action: PayloadAction<number>) => {
      state.next = action.payload;
    },
    previousPage: (state, action: PayloadAction<number>) => {
      state.previous = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
  },
  extraReducers: (builder) => builder
    .addCase(fetchApplicationsList.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    })
    .addCase(fetchApplicationsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = undefined;

      if (action.payload.next === null && action.payload.previous === null) {
        applicationsPageAdapter.removeAll(state);
      }

      applicationsPageAdapter.addMany(state, action.payload.results);
      state.count = action.payload.count;
      state.next = extractPageNumber(action.payload.next);
      state.previous = extractPageNumber(action.payload.previous);
      state.totalPages = Math.ceil(action.payload.count / 10);
    })
    .addCase(fetchApplicationsList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    })
    .addCase(deleteCheckedItems.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    })
    .addCase(deleteCheckedItems.fulfilled, (state) => {
      state.isLoading = false;
      state.allIsChecked = false;
    })
    .addCase(deleteCheckedItems.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }),
});

export const { actions: applicationsPageActions } = applicationsPageSlice;
export const { reducer: applicationsPageReducer } = applicationsPageSlice;
