// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_title__Ss\\+NU {
  font: var(--font-xl);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF","sourcesContent":[".title {\n  font: var(--font-xl);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `Title_title__Ss+NU`
};
export default ___CSS_LOADER_EXPORT___;
