// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font: var(--font-s);
  color: var(--black-color);
}

.page-wrapper {
  flex: 1 1 100%;
  overflow: hidden;
  margin-right: 32px;
}
@media (max-width: 760px) {
  .page-wrapper {
    margin: 20px auto;
    padding: 0 10px;
    width: 100%;
  }
}

.content-page {
  display: flex;
  flex: 1 1 100%;
}
@media (max-width: 760px) {
  .content-page {
    flex-direction: column-reverse;
    gap: 40px;
  }
}

.tp-calendar-header p {
  font: var(--font-m);
  font-weight: 700;
  color: #000 !important;
}

.tp-calendar-month-select {
  display: flex;
}
.tp-calendar-month-select > div {
  border: 1px solid var(--primary-color) !important;
  flex: 1 0 50% !important;
  padding: 10px;
}
.tp-calendar-month-select > div:hover {
  background: var(--primary-color);
}
.tp-calendar-month-select > div:hover p {
  color: #fff !important;
}
.tp-calendar-month-select > div p {
  color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/styles/App.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;AACF;;AAGA;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AAAF;AAEE;EALF;IAMI,iBAAA;IACA,eAAA;IACA,WAAA;EACF;AACF;;AAEA;EACE,aAAA;EACA,cAAA;AACF;AACE;EAJF;IAKI,8BAAA;IACA,SAAA;EAEF;AACF;;AAII;EACE,mBAAA;EACA,gBAAA;EACA,sBAAA;AADN;;AAMA;EACE,aAAA;AAHF;AAKE;EACE,iDAAA;EACA,wBAAA;EACA,aAAA;AAHJ;AAKI;EACE,gCAAA;AAHN;AAKM;EACE,sBAAA;AAHR;AAQE;EACE,sBAAA;AANJ","sourcesContent":[".App {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  font: var(--font-s);\n  color: var(--black-color);\n}\n\n\n.page-wrapper {\n  flex: 1 1 100%;\n  overflow: hidden;\n  margin-right: 32px;\n\n  @media (max-width: 760px) {\n    margin: 20px auto;\n    padding: 0 10px;\n    width: 100%;\n  }\n}\n\n.content-page {\n  display: flex;\n  flex: 1 1 100%;\n\n  @media (max-width: 760px) {\n    flex-direction: column-reverse;\n    gap: 40px;\n  }\n}\n\n// Calendar settings\n.tp-calendar {\n  &-header {\n    p {\n      font: var(--font-m);\n      font-weight: 700;\n      color: #000 !important;\n    }\n  }\n}\n\n.tp-calendar-month-select {\n  display: flex;\n\n  &>div {\n    border: 1px solid var(--primary-color) !important;\n    flex: 1 0 50% !important;\n    padding: 10px;\n\n    &:hover {\n      background: var(--primary-color);\n\n      p {\n        color: #fff !important;\n      }\n    }\n  }\n\n  &>div p {\n    color: #000 !important;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
