// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar_calendar__TmAwN {
  max-width: 375px;
  min-width: 375px;
  margin: 0 auto;
}

.Calendar_icon__xzIC3 {
  fill: var(--dark-gray-bg-color);
  stroke: var(--dark-gray-bg-color);
}

.Calendar_arrowLeft__1tLjP {
  transform: rotate(180deg);
}

.Calendar_button__ZHpJs {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Calendar/Calendar.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;AACF;;AAEA;EACE,+BAAA;EACA,iCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".calendar {\n  max-width: 375px;\n  min-width: 375px;\n  margin: 0 auto;\n}\n\n.icon {\n  fill: var(--dark-gray-bg-color);\n  stroke: var(--dark-gray-bg-color);\n}\n\n.arrowLeft {\n  transform: rotate(180deg);\n}\n\n.button {\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": `Calendar_calendar__TmAwN`,
	"icon": `Calendar_icon__xzIC3`,
	"arrowLeft": `Calendar_arrowLeft__1tLjP`,
	"button": `Calendar_button__ZHpJs`
};
export default ___CSS_LOADER_EXPORT___;
