// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropdownMenu_dropdownMenu__4LoyG {
  position: relative;
  min-width: 160px;
}

.DropdownMenu_preview__c2pVk {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 23px;
  box-shadow: 0 4px 10px 0 var(--shadow-color);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.DropdownMenu_text__YkcLs {
  margin-left: 8px;
  font-size: var(--font-size-s2);
}

.DropdownMenu_menu__\\+TknM {
  position: absolute;
  display: grid;
  overflow: hidden;
  grid-template-rows: 0;
  opacity: 0;
  background: var(--gray-bg-color);
  width: 100%;
  border-radius: 0 0 23px 23px;
  transition: all 0.3s ease-in-out;
}

.DropdownMenu_column__3TlR4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 16px 13px;
}

.DropdownMenu_button__KPVBl {
  font-size: var(--font-size-s2);
}

.DropdownMenu_pass__gzZqB {
  color: var(--primary-color);
  font-weight: var(--font-weight-xl);
}

.DropdownMenu_active__PxnZ5 .DropdownMenu_menu__\\+TknM {
  grid-template-rows: 1fr;
  opacity: 1;
}
.DropdownMenu_active__PxnZ5 .DropdownMenu_preview__c2pVk {
  position: relative;
  z-index: 100;
  background: var(--gray-bg-color);
  border-radius: 23px 23px 0 0;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/widgets/DropdownMenu/DropdownMenu.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,4CAAA;EACA,eAAA;EACA,gCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,qBAAA;EACA,UAAA;EACA,gCAAA;EACA,WAAA;EACA,4BAAA;EACA,gCAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,8BAAA;AAAF;;AAGA;EACE,2BAAA;EACA,kCAAA;AAAF;;AAIE;EACE,uBAAA;EACA,UAAA;AADJ;AAIE;EACE,kBAAA;EACA,YAAA;EACA,gCAAA;EACA,4BAAA;EACA,gBAAA;AAFJ","sourcesContent":[".dropdownMenu {\n  position: relative;\n  min-width: 160px;\n\n}\n\n.preview {\n  display: flex;\n  align-items: center;\n  padding: 8px 16px;\n  border-radius: 23px;\n  box-shadow: 0 4px 10px 0 var(--shadow-color);\n  cursor: pointer;\n  transition: all 0.3s ease-in-out;\n}\n\n.text {\n  margin-left: 8px;\n  font-size: var(--font-size-s2);\n}\n\n.menu {\n  position: absolute;\n  display: grid;\n  overflow: hidden;\n  grid-template-rows: 0;\n  opacity: 0;\n  background: var(--gray-bg-color);\n  width: 100%;\n  border-radius: 0 0 23px 23px;\n  transition: all 0.3s ease-in-out;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 6px 16px 13px;\n}\n\n.button {\n  font-size: var(--font-size-s2);\n}\n\n.pass {\n  color: var(--primary-color);\n  font-weight: var(--font-weight-xl);\n}\n\n.active {\n  .menu {\n    grid-template-rows: 1fr;\n    opacity: 1;\n  }\n\n  .preview {\n    position: relative;\n    z-index: 100;\n    background: var(--gray-bg-color);\n    border-radius: 23px 23px 0 0;\n    box-shadow: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownMenu": `DropdownMenu_dropdownMenu__4LoyG`,
	"preview": `DropdownMenu_preview__c2pVk`,
	"text": `DropdownMenu_text__YkcLs`,
	"menu": `DropdownMenu_menu__+TknM`,
	"column": `DropdownMenu_column__3TlR4`,
	"button": `DropdownMenu_button__KPVBl`,
	"pass": `DropdownMenu_pass__gzZqB`,
	"active": `DropdownMenu_active__PxnZ5`
};
export default ___CSS_LOADER_EXPORT___;
