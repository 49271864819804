// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text_xl__LV7DZ.Text_title__uGLsM {
  font: var(--font-xl);
  margin-bottom: 6px;
}
.Text_xl__LV7DZ.Text_text__yck2R {
  font: var(--font-m);
}

.Text_m__329IT.Text_title__uGLsM {
  font: var(--font-m);
  margin-bottom: 6px;
  font-weight: 700;
}
.Text_m__329IT.Text_text__yck2R {
  font: var(--font-s);
}

.Text_normal__I71l6 .Text_title__uGLsM, .Text_normal__I71l6 .Text_text__yck2R {
  color: var(--black-color);
}

.Text_error__KCmzm .Text_title__uGLsM, .Text_error__KCmzm .Text_text__yck2R {
  color: var(--red-color);
}

.Text_left__HIZll {
  text-align: left;
}

.Text_center__6azZ1 {
  text-align: center;
}

.Text_right__8pNA1 {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Text/Text.module.scss"],"names":[],"mappings":"AAEE;EACE,oBAAA;EACA,kBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;;AAOE;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;AAJJ;AAOE;EACE,mBAAA;AALJ;;AAYE;EACE,yBAAA;AATJ;;AAcE;EACE,uBAAA;AAXJ;;AAeA;EACE,gBAAA;AAZF;;AAeA;EACE,kBAAA;AAZF;;AAcA;EACE,iBAAA;AAXF","sourcesContent":["\n.xl {\n  &.title {\n    font: var(--font-xl);\n    margin-bottom: 6px;\n  }\n    \n  &.text {\n    font: var(--font-m);\n  }    \n}\n\n.m {\n  &.title {\n    font: var(--font-m);\n    margin-bottom: 6px;\n    font-weight: 700;\n  }\n    \n  &.text {\n    font: var(--font-s);\n  }\n    \n}\n\n\n.normal {\n  .title, .text {\n    color: var(--black-color);\n  }\n}\n\n.error {\n  .title, .text {\n    color: var(--red-color);\n  }\n}\n\n.left {\n  text-align: left;\n}\n\n.center {\n  text-align: center;\n}\n.right {\n  text-align: right;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xl": `Text_xl__LV7DZ`,
	"title": `Text_title__uGLsM`,
	"text": `Text_text__yck2R`,
	"m": `Text_m__329IT`,
	"normal": `Text_normal__I71l6`,
	"error": `Text_error__KCmzm`,
	"left": `Text_left__HIZll`,
	"center": `Text_center__6azZ1`,
	"right": `Text_right__8pNA1`
};
export default ___CSS_LOADER_EXPORT___;
