// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsList_reportsList__-q9k6 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ReportsList_controls__Qeeab {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.ReportsList_btn__k-Fen {
  background-color: var(--primary-bg-light-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/ReportsPage/ui/ReportsList/ReportsList.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,+CAAA;AACF","sourcesContent":[".reportsList {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.controls {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n}\n\n.btn {\n  background-color: var(--primary-bg-light-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportsList": `ReportsList_reportsList__-q9k6`,
	"controls": `ReportsList_controls__Qeeab`,
	"btn": `ReportsList_btn__k-Fen`
};
export default ___CSS_LOADER_EXPORT___;
