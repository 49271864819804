// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__4t0lf {
  min-width: var(--sidebar-width);
  margin-right: 51px;
}
@media (max-width: 760px) {
  .Sidebar_sidebar__4t0lf {
    margin-right: 0;
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/Sidebar/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,kBAAA;AACF;AACE;EAJF;IAKI,eAAA;IACA,mBAAA;EAEF;AACF","sourcesContent":[".sidebar {\n  min-width: var(--sidebar-width);\n  margin-right: 51px;\n\n  @media (max-width: 760px) {\n    margin-right: 0;\n    margin-bottom: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__4t0lf`
};
export default ___CSS_LOADER_EXPORT___;
