// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportsPageContent_title__ILvRf {
  margin-bottom: 24px;
}

.ReportsPageContent_navigation__jynRb {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 32px;
}

.ReportsPageContent_btns__gwMjV,
.ReportsPageContent_selects__fPrIl,
.ReportsPageContent_secondBtns__IACul {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.ReportsPageContent_selectSearch__Z2e8f {
  width: 140px;
  height: 35px;
}

.ReportsPageContent_date__RqhdI {
  width: 120px;
  border-radius: 13px;
}

@media (max-width: 940px) {
  .ReportsPageContent_navigation__jynRb {
    flex-wrap: wrap;
  }
  .ReportsPageContent_selects__fPrIl {
    order: 3;
    width: 100%;
    flex-direction: column;
  }
  .ReportsPageContent_selectSearch__Z2e8f,
  .ReportsPageContent_date__RqhdI {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ReportsPage/ui/ReportsPageContent/ReportsPageContent.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;;;EAGE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,eAAA;EACF;EAEA;IACE,QAAA;IACA,WAAA;IACA,sBAAA;EAAF;EAGA;;IAEE,WAAA;EADF;AACF","sourcesContent":[".title {\n  margin-bottom: 24px;\n}\n\n.navigation {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n  margin-bottom: 32px;\n}\n\n.btns,\n.selects,\n.secondBtns {\n  display: flex;\n  flex-direction: row;\n  gap: 12px;\n}\n\n.selectSearch {\n  width: 140px;\n  height: 35px;\n}\n\n.date {\n  width: 120px;\n  border-radius: 13px;\n}\n\n@media (max-width: 940px) {\n  .navigation {\n    flex-wrap: wrap;\n  }\n\n  .selects {\n    order: 3;\n    width: 100%;\n    flex-direction: column;\n  }\n\n  .selectSearch,\n  .date {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `ReportsPageContent_title__ILvRf`,
	"navigation": `ReportsPageContent_navigation__jynRb`,
	"btns": `ReportsPageContent_btns__gwMjV`,
	"selects": `ReportsPageContent_selects__fPrIl`,
	"secondBtns": `ReportsPageContent_secondBtns__IACul`,
	"selectSearch": `ReportsPageContent_selectSearch__Z2e8f`,
	"date": `ReportsPageContent_date__RqhdI`
};
export default ___CSS_LOADER_EXPORT___;
