import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers';
import { USER_LOCALSTORAGE_DATA } from 'shared/const/localStorage';
import { userActions } from 'entities/User';
import { changePasswordActions } from '../slice/changePasswordSlice';
import { ChangePasswordFormData } from '../type/changePassword';

export const changePasswordService = createAsyncThunk<
  void,
  ChangePasswordFormData,
  ThunkConfig<string>
>(
  'changePassword/changePasswordService',
  async (formData, { extra, rejectWithValue, dispatch }) => {
    const userData = localStorage.getItem(USER_LOCALSTORAGE_DATA);

    const changePasswordData: ChangePasswordFormData = {
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
        repeatPassword: formData.repeatPassword,
    };

    if (!userData) {
      throw new Error('Ошибка аутентификации пользователя!');
    }

    try {
      const response = await extra.api.put<ChangePasswordFormData>('/api/v1/users/change_password/', {
        old_password: changePasswordData.oldPassword, new_password: changePasswordData.newPassword,
      });

      if (!response.data) {
        throw new Error('Ошибка смены пароля!');
      }

      dispatch(changePasswordActions.closeForm());
    } catch (e: any) {
      if (e.response?.status === 401) {
        dispatch(userActions.logout());
      }
      return rejectWithValue(e.response?.data || 'Ошибка смены пароля');
    }
  },
);
