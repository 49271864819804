// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddReportForm_addReportForm__kU6Jn {
  display: flex;
  flex-direction: column;
}

.AddReportForm_title__2bZZ2 {
  margin-bottom: 26px;
}

.AddReportForm_input__zr44Z:not(:last-child) {
  margin-bottom: 6px;
}

.AddReportForm_btn__Dv5Gu {
  margin-top: 30px;
  padding: 10px;
  width: 193px;
}`, "",{"version":3,"sources":["webpack://./src/features/AddReport/ui/AddReportForm/AddReportForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAGE;EACE,kBAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,aAAA;EACA,YAAA;AADF","sourcesContent":[".addReportForm {\n  display: flex;\n  flex-direction: column;\n}\n\n.title {\n  margin-bottom: 26px;\n}\n\n.input {\n  &:not(:last-child) {\n    margin-bottom: 6px;\n  }\n}\n\n.btn {\n  margin-top: 30px;\n  padding: 10px;\n  width: 193px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addReportForm": `AddReportForm_addReportForm__kU6Jn`,
	"title": `AddReportForm_title__2bZZ2`,
	"input": `AddReportForm_input__zr44Z`,
	"btn": `AddReportForm_btn__Dv5Gu`
};
export default ___CSS_LOADER_EXPORT___;
