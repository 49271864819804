// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MultiselectItem_optionCheckbox__Nu4BC {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.MultiselectItem_checkbox__mP9Gr {
  margin-left: 10px;
  pointer-events: none;
}

.MultiselectItem_checkedText__0xmkK {
  color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Select/MultiselectItem/MultiselectItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,iBAAA;EACA,oBAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".optionCheckbox {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n}\n\n.checkbox {\n  margin-left: 10px;\n  pointer-events: none;\n}\n\n.checkedText {\n  color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionCheckbox": `MultiselectItem_optionCheckbox__Nu4BC`,
	"checkbox": `MultiselectItem_checkbox__mP9Gr`,
	"checkedText": `MultiselectItem_checkedText__0xmkK`
};
export default ___CSS_LOADER_EXPORT___;
