// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigation_navigation__nuE\\+0 {
  display: flex;
  flex-direction: column;
  border-radius: 0 15px 15px 0;
  background: #FFF;
  box-shadow: 0 4px 10px 0 var(--shadow-color);
  overflow: hidden;
  width: var(--sidebar-width);
  transition: width 0.3s ease-in-out;
}
@media (max-width: 760px) {
  .Navigation_navigation__nuE\\+0 {
    margin: 0 auto;
    padding: 10px;
    gap: 8px;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
}

.Navigation_collapsed__y3vNC {
  width: var(--sidebar-collapsed-width);
}
@media (max-width: 760px) {
  .Navigation_collapsed__y3vNC {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/Sidebar/Navigation/Navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,gBAAA;EACA,4CAAA;EACA,gBAAA;EACA,2BAAA;EACA,kCAAA;AACF;AACE;EAVF;IAWI,cAAA;IACA,aAAA;IACA,QAAA;IACA,WAAA;IACA,gBAAA;IACA,gBAAA;EAEF;AACF;;AACA;EACE,qCAAA;AAEF;AAAE;EAHF;IAII,WAAA;EAGF;AACF","sourcesContent":[".navigation {\n  display: flex;\n  flex-direction: column;\n  border-radius: 0 15px 15px 0;\n  background: #FFF;\n  box-shadow: 0 4px 10px 0 var(--shadow-color);\n  overflow: hidden;\n  width: var(--sidebar-width);\n  transition: width 0.3s ease-in-out;\n\n  @media (max-width: 760px) {\n    margin: 0 auto;\n    padding: 10px;\n    gap: 8px;\n    width: 100%;\n    box-shadow: none;\n    border-radius: 0;\n  }\n}\n\n.collapsed {\n  width: var(--sidebar-collapsed-width);\n\n  @media (max-width: 760px) {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `Navigation_navigation__nuE+0`,
	"collapsed": `Navigation_collapsed__y3vNC`
};
export default ___CSS_LOADER_EXPORT___;
