import { classNames } from 'shared/lib/classNames/classNames';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { FormType, useForm } from 'shared/lib/hooks/useForm/useForm';
import { Text, TextAlign } from 'shared/ui/Text/Text';
import { changePasswordActions } from 'features/ChangePassword/model/slice/changePasswordSlice';
import { changePasswordService } from 'features/ChangePassword/model/services/changePasswordService';
import {
  getChangePasswordOld,
  getChangePasswordNew,
  getChangePasswordRepeat,
} from '../../model/selectors/changePasswordSelectors';
import cls from './ChangePasswordForm.module.scss';

interface ChangePasswordFormProps {
  className?: string;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const currentPassword = useSelector(getChangePasswordOld);
  const newPassword = useSelector(getChangePasswordNew);
  const confirmPassword = useSelector(getChangePasswordRepeat);

  const changeCurrentPasswordHandler = useCallback((value: string) => {
    dispatch(changePasswordActions.setOldPassword(value));
  }, [dispatch]);

  const changeNewPasswordHandler = useCallback((value: string) => {
    dispatch(changePasswordActions.setNewPassword(value));
  }, [dispatch]);

  const changeConfirmPasswordHandler = useCallback((value: string) => {
    dispatch(changePasswordActions.setRepeatPassword(value));
  }, [dispatch]);

  const onSubmitHandler = useCallback(() => {
    dispatch(changePasswordService({
      oldPassword: currentPassword,
      newPassword,
      repeatPassword: confirmPassword,
    }));
  }, [dispatch, currentPassword, newPassword, confirmPassword]);

  const onCancelHandler = useCallback(() => {
    dispatch(changePasswordActions.closeForm());
  }, [dispatch]);

  const { Form } = useForm({
    fields: [
      {
        id: 'currentPassword',
        type: FormType.PASSWORD,
        value: currentPassword,
        placeholder: 'Текущий пароль',
        onChange: changeCurrentPasswordHandler,
        rules: {
          required: true,
        },
      },
      {
        id: 'newPassword',
        type: FormType.PASSWORD,
        value: newPassword,
        placeholder: 'Новый пароль',
        onChange: changeNewPasswordHandler,
        rules: {
          required: true,
        },
      },
      {
        id: 'confirmPassword',
        type: FormType.PASSWORD,
        value: confirmPassword,
        placeholder: 'Подтверждение пароля',
        onChange: changeConfirmPasswordHandler,
        rules: {
          required: true,
        },
      },
    ],
    onSubmit: onSubmitHandler,
    onCancel: onCancelHandler,
    submitTitle: 'Сохранить',
    cancelTitle: 'Отмена',
  });

  return (
    <div className={classNames(cls.changePasswordForm, {}, [className])}>
      <Text className={cls.title} title="Смена пароля" textAlign={TextAlign.CENTER} />
      {Form}
    </div>
  );
};
