// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileInput_fileInput__NTfQe {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--white-color);
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
  padding: 7px 6px;
  width: 100%;
  min-height: 120px;
  outline: 1px dashed var(--dark-gray-color);
  outline-offset: -6px;
  transition: outline, outline-offset 0.2s ease-in-out;
}

.FileInput_dropFile__s8Ulp {
  outline-offset: -16px;
}

.FileInput_error__QyVZm {
  outline: 1px dashed var(--red-color);
}

.FileInput_fileContent__Jwy-g {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FileInput_label__bV\\+6i {
  color: var(--primary-color);
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FileInput_label__bV\\+6i:hover {
  text-decoration: underline;
}

.FileInput_input__uvUD8 {
  opacity: 0;
  position: absolute;
  width: 1px;
  height: 1px;
  top: 50%;
  left: 50%;
}

.FileInput_fileName__NI-zh {
  margin-top: 25px;
  font: var(--font-s);
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/FileInput/FileInput.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,gDAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,0CAAA;EACA,oBAAA;EACA,oDAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,oCAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,2BAAA;EACA,eAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AACI;EACI,0BAAA;AACR;;AAGA;EACI,UAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".fileInput {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    background: var(--white-color);\n    border-radius: 10px;\n    box-shadow: 0px 4px 10px 0px var(--shadow-color);\n    padding: 7px 6px;\n    width: 100%;\n    min-height: 120px;\n    outline: 1px dashed var(--dark-gray-color);\n    outline-offset: -6px;\n    transition: outline, outline-offset .2s ease-in-out;\n}\n\n.dropFile {\n    outline-offset: -16px;\n}\n\n.error {\n    outline: 1px dashed var(--red-color);\n}\n\n.fileContent {\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.label {\n    color: var(--primary-color);\n    cursor: pointer;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    &:hover {\n        text-decoration: underline;\n    }\n}\n\n.input {\n    opacity: 0;\n    position: absolute;\n    width: 1px;\n    height: 1px;\n    top: 50%;\n    left: 50%;\n}\n\n.fileName {\n    margin-top: 25px;\n    font: var(--font-s);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileInput": `FileInput_fileInput__NTfQe`,
	"dropFile": `FileInput_dropFile__s8Ulp`,
	"error": `FileInput_error__QyVZm`,
	"fileContent": `FileInput_fileContent__Jwy-g`,
	"label": `FileInput_label__bV+6i`,
	"input": `FileInput_input__uvUD8`,
	"fileName": `FileInput_fileName__NI-zh`
};
export default ___CSS_LOADER_EXPORT___;
