// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_inputContainer__n8aBq {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 100%;
  border-radius: 9px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border 0.2s ease-in-out;
}

.Input_error__R9psO {
  border: 1px solid var(--red-color);
}

.Input_success__ZJy7n {
  border: 1px solid var(--green-color);
}

.Input_gray__oTQDr {
  background-color: var(--gray-bg-color);
}

.Input_white__Qet6q {
  background-color: var(--white-color);
  max-height: 35px;
  box-shadow: 0px 4px 10px 0px var(--shadow-color);
  display: flex;
  justify-content: center;
}

.Input_input__Vnl8Q {
  background-color: transparent;
  padding: 17px 20px;
  columns: var(--black-color);
  max-height: 100%;
}

.Input_label__7H1CW {
  font-weight: 700;
  margin-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Input/Input.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,6BAAA;EACA,mCAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACE,oCAAA;EACA,gBAAA;EACA,gDAAA;EACA,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,6BAAA;EACA,kBAAA;EACA,2BAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".inputContainer {\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  max-height: 100%;\n  border-radius: 9px;\n  overflow: hidden;\n  border: 1px solid transparent;\n  transition: border .2s ease-in-out;\n}\n\n.error {\n  border: 1px solid var(--red-color);\n}\n\n.success {\n  border: 1px solid var(--green-color);\n}\n\n.gray {\n  background-color: var(--gray-bg-color);\n}\n\n.white {\n  background-color: var(--white-color);\n  max-height: 35px;\n  box-shadow: 0px 4px 10px 0px var(--shadow-color);\n  display: flex;\n  justify-content: center;\n}\n\n.input {\n  background-color: transparent;\n  padding: 17px 20px;\n  columns: var(--black-color);\n  max-height: 100%;\n}\n\n.label {\n  font-weight: 700;\n  margin-bottom: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `Input_inputContainer__n8aBq`,
	"error": `Input_error__R9psO`,
	"success": `Input_success__ZJy7n`,
	"gray": `Input_gray__oTQDr`,
	"white": `Input_white__Qet6q`,
	"input": `Input_input__Vnl8Q`,
	"label": `Input_label__7H1CW`
};
export default ___CSS_LOADER_EXPORT___;
